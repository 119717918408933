import React, { useState } from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useShoppingCart } from "use-shopping-cart"
import styled from "styled-components"

import Layout from "../templates/layout/layout"
import SEO from "../templates/layout/seo"

import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { ListGroup, ListGroupItem, Button } from "reactstrap"
import { InputGroup, InputGroupAddon, Input } from "reactstrap"

import { AiOutlineClose } from "react-icons/ai"

export default function Cart(props) {
  return (
    <Layout>
      <SEO title="Warenkorb - ArtGreeetings" />
      <Container fluid>
        <h3 className="my-4 my-md-5">Dein Warenkorb</h3>
        <div className="mb-5 pb-5 mx-xl-5 px-xl-5">
          <Placeholder />
          <CartItems />
          <CartSum />
        </div>
      </Container>
    </Layout>
  )
}

const Placeholder = () => {
  const { cartCount } = useShoppingCart()

  if (cartCount > 0) return null

  return (
    <div className="my-5 pt-5 text-center">
      <p>Du hast bisher noch keine Artikel in deinen Warenkorb gelegt.</p>
      <Button
        className="mt-5 px-4"
        color="primary"
        size="lg"
        outline
        tag={Link}
        to={"/"}
      >
        Weiter stöbern
      </Button>
    </div>
  )
}

const CartItems = () => {
  const { cartDetails, setItemQuantity } = useShoppingCart()
  const { incrementItem, decrementItem, removeItem } = useShoppingCart()
  const cart = Object.entries(cartDetails).map(([k, v]) => v)

  return (
    <StyledList>
      {cart.map((item) => (
        <ListGroupItem key={item.id} className="d-flex px-0">
          <div className="d-flex align-items-start">
            <GatsbyImage image={getImage(item.mainImage)} alt={item.name} />
          </div>
          <div className="d-flex flex-column flex-grow-1 justify-content-between pl-3 pl-md-4 pt-sm-1">
            <Row>
              <Col
                xs="9"
                className="d-flex flex-column justify-content-start text-truncate"
              >
                <a
                  className="lead text-body text-truncate"
                  href={`/product/${item.slug}`}
                >
                  {item.name}
                </a>
                <div className="small text-muted text-truncate">
                  {item.variation}
                  {item.personalization && (
                    <span>: {item.personalization}</span>
                  )}
                </div>
              </Col>

              <Col
                xs="3"
                className="d-flex align-items-start justify-content-end"
              >
                <Button outline size="sm" onClick={() => removeItem(item.id)}>
                  <AiOutlineClose size={17} />
                </Button>
              </Col>
            </Row>
            <Row>
              <Col
                md={{ size: "7", offset: "5" }}
                className="d-flex d-md-flex align-items-center justify-content-start justify-content-md-end"
              >
                <div>{item.formattedPrice}</div>
                <InputGroup className="mx-auto">
                  <InputGroupAddon addonType="prepend">
                    <Button
                      outline
                      disabled={item.quantity === 1}
                      onClick={() => decrementItem(item.id)}
                    >
                      -
                    </Button>
                  </InputGroupAddon>
                  <Input
                    value={item.quantity}
                    onChange={(e) =>
                      setItemQuantity(item.id, parseInt(e.target.value))
                    }
                  />
                  <InputGroupAddon addonType="append">
                    <Button outline onClick={() => incrementItem(item.id)}>
                      +
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
                <div>{item.formattedValue}</div>
              </Col>
            </Row>
          </div>
        </ListGroupItem>
      ))}
    </StyledList>
  )
}

const CartSum = () => {
  const { cartCount, totalPrice, formattedTotalPrice } = useShoppingCart()
  const sum = parseFloat(+(totalPrice / 100 + 1.6))
    .toFixed(2)
    .replace(".", ",")

  if (cartCount < 1) return null

  return (
    <StyledSum>
      <Row>
        <Col md={{ size: "7", offset: "5" }}>
          <div className="my-3 py-3 border-top">
            <div className="d-flex justify-content-between mb-2">
              <span>Zwischensumme:</span>
              <span className="ml-auto font-size-sm">
                {formattedTotalPrice}
              </span>
            </div>
            <div className="d-flex justify-content-between mb-2 pb-2 border-bottom">
              <span>Versandkosten:</span>
              <span className="ml-auto font-size-sm">1,60 €</span>
            </div>
            <div className="d-flex justify-content-between mb-2 lead">
              <span>Gesamtbetrag:</span>
              <span className="ml-auto font-size-sm">{sum} €</span>
            </div>
          </div>
          <Button
            tag={Link}
            to={"/checkout"}
            className="mb-2"
            color="dark"
            size="lg"
            block
            disabled={cartCount === 0}
            onClick={() => {
              window.dataLayer = window.dataLayer || []
              window.dataLayer.push({ event: "begin_checkout" })
            }}
          >
            Zur Kasse
          </Button>
        </Col>
      </Row>
    </StyledSum>
  )
}

const OrderSummary = (props) => {
  const { cartCount, totalPrice, formattedTotalPrice } = useShoppingCart()

  const sum = (+(totalPrice / 100 + 1.6).toFixed(2)).toLocaleString("de")

  return (
    <StyledCard className="mt-3 mt-md-0 mb-3 bg-light">
      <CardBody>
        <ListGroup flush>
          <ListGroupItem className="d-flex bg-light px-0 pb-0 border-0">
            <span>{cartCount} Artikel</span>
            <span className="ml-auto font-size-sm">{formattedTotalPrice}</span>
          </ListGroupItem>
          <ListGroupItem className="d-flex bg-light px-0">
            <span>Versandkosten</span>
            <span className="ml-auto">1,60 €</span>
          </ListGroupItem>
          <ListGroupItem className="d-flex bg-light lead px-0 pb-4 ">
            <span>Gesamtbetrag</span>
            <span className="ml-auto">{sum}0 €</span>
          </ListGroupItem>
          <ListGroupItem className="justify-content-center text-center bg-light border-0 py-4">
            <small>Deine Bestellung wird in 1-3 Tagen versandt.</small>
          </ListGroupItem>
        </ListGroup>
      </CardBody>
    </StyledCard>
  )
}

const StyledList = styled(ListGroup)`
 @media (min-width: 992px) {
        margin-bottom: 1rem;
      }
  .list-group-item {
    border: 1px solid #e5e5e5;
    border-top-width: 0;
    border-left: none;
    border-right: none;
    border-radius: 0;
    :last-child {
      border-bottom: none;
    }
    .gatsby-image-wrapper {
      width: 5rem ;
      @media (min-width: 576px) {
        width: 6rem ;
      }
      @media (min-width: 768px) {
        width: 7rem ;
      }
      img {
        border-radius: ${({ theme }) => theme.borderRadius};
      }
    }
     button {
      border: none;
      border-radius: 100%;
      color: ${({ theme }) => theme.textDefault};
      :hover {
        background-color: ${({ theme }) => theme.backgroundSecondary};
      }
      :focus{
        box-shadow: none
      }
    }
    .input-group {
      width: 8rem;
      button {
        border: none;
        border-radius: 100%;
        width: 2.5rem;
        color: ${({ theme }) => theme.textDefault};
        :hover:not(.disabled) {
          background-color: ${({ theme }) => theme.backgroundSecondary};
        }
        :
      }
      input {
        border: none;
        text-align: center;
      }
    }
  }
`
const StyledSum = styled.div`
  .lead {
    font-weight: 400;
  }
  @media (min-width: 768px) {
    margin-left: 7rem;
  }
`
const StyledCard = styled(Card)`
  @media (max-width: 576px) {
    margin: 0 -15px;
    border-radius: 0;
    border-left: none;
    border-right: none;
  }
`
